<template>
  <div class="index-tc" v-loading="loading" :element-loading-text="loadingText">
    <div class="header_title">
      <p>心理CT数据可视化看板</p>
    </div>
    <div class="chart">
      <div class="chart-col-1">
        <div class="col-1-1">
          <div class="col-1-1-1">
            <div class="num">
              <div class="numberback">
                <p>总人数</p>
                <p>{{tatistical_user.total}}</p>
              </div>
              <div class="numberback">
                <p>男性</p>
                <p>{{tatistical_user.male}}</p>
              </div>
              <div class="numberback">
                <p>女性</p>
                <p>{{tatistical_user.female}}</p>
              </div>
            </div>
          </div>
          <div class="col-1-1-2">
            <div id="monthly-data" :style="{width: '100%', height: '100%'}"></div>
          </div>
        </div>
        <div class="col-1-2">
          <div id="chart-section-distribution-data" :style="{width: '100%', height: '100%'}"></div>
        </div>
      </div>
      <div class="chart-col-2">
        <div class="col-2-1">
          <div class="col-2-1-1">
            <div class="col-2-1-1-1">
              <div class="title">测评性别数据</div>
              <div class="chart">
                <div id="chart-sex-data" :style="{width: '100%', height: '100%'}"></div>
              </div>
            </div>
            <div class="col-2-1-1-2">
              <div class="title">测评警告数据</div>
              <div class="chart">
                <div id="chart-warning-data" :style="{width: '100%', height: '100%'}"></div>
              </div>
            </div>
          </div>
          <div class="col-2-1-2">
            <div id="chart-age-data" :style="{width: '100%', height: '100%'}"></div>
          </div>
        </div>
        <div class="col-2-2">
          <div id="chart-scale-data" :style="{width: '100%', height: '100%'}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadEcharts } from "@/api/home.js";
export default {
  data() {
    return {
      loadingText:"正在加载可视化看板",
      loading: true,
      mouth: "",
      department: "",
      sex: "",
      warning: "",
      age: "",
      measure: "",
      tatistical_user: "",
      data: ""
    };
  },
  created() {
    // 发送图表数据请求
    this.loadEchartsData();
    ////console.log(this.department);
  },
  mounted() {
    // 延迟1秒对图表进行加载
    // setTimeout(() => {}, 1200);
  },
  methods: {
    // 测评年龄
    charAgeData() {
      // 基于准备好的dom，初始化echarts实例
      let ageData = this.$echarts.init(
        document.getElementById("chart-age-data")
      );
      // 绘制图表
      ageData.setOption({
        title: {
          text: "评测年龄分布",
          textStyle: {
            align: "center",
            // color: "red",
            fontSize: 20
          },
          left: "center"
        },
        backgroundColor: "#FFFFFF",
        tooltip: {
          trigger: "axis",
          confine: true,
          position: function([x, y]) {
            var newX = x + 200;
            ////console.log(newX)
            return [newX, y];
          },
          formatter: function(params) {
            ////console.log(params);
          },
          padding: [
            11, // 上
            19, // 右
            13, // 下
            13 // 左
          ]
        },
        radar: {
          center: ["50%", "60%"],
          radius: "65%",
          name: {
            textStyle: {
              color: "#3D4D65"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#030303",
              width: 3,
              type: "solid"
            }
          },
          splitLine: {
            lineStyle: {
              color: ["#515151"],
              width: 1,
              type: "dashed"
            }
          },
          splitArea: {
            areaStyle: {
              color: [
                "rgba(255, 255, 255, 1)",
                "rgba(255, 255, 255, 0)",
                "rgba(255, 255, 255, 0)",
                "rgba(255, 255, 255, 0)"
              ],
              shadowColor: "#fff"
            }
          },
          indicator: [
            {
              name: this.age.indicator[0],
              max: this.age.series[0] + 10
            },
            {
              name: this.age.indicator[1],
              max: this.age.series[1] + 10
            },
            {
              name: this.age.indicator[2],
              max: this.age.series[2] + 10
            },
            {
              name: this.age.indicator[3],
              max: this.age.series[3] + 10
            },
            {
              name: this.age.indicator[4],
              max: this.age.series[4] + 10
            },
            {
              name: this.age.indicator[5],
              max: this.age.series[5] + 10
            }
          ]
        },
        grid: {
          top: " "
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: this.age.series,
                name: "数据",
                itemStyle: {
                  normal: {
                    borderWidth: 2,
                    color: "#26A0FF"
                  }
                },
                areaStyle: {
                  color: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.66,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#00F5FF" // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#00B2EE" // 100% 处的颜色
                      }
                    ],
                    global: false // 缺省为 false
                  }
                }
              }
            ],
            label: {
              normal: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
                color: "#333",
                position: "insideTopRight"
              }
            }
          }
        ]
      });
    },
    // 评测性别数据
    charSexData() {
      // 基于准备好的dom，初始化echarts实例
      let sexData = this.$echarts.init(
        document.getElementById("chart-sex-data")
      );
      // 绘制图表
      sexData.setOption({
        title: {
          text: "总人数",
          subtext: this.sex.headcount,
          x: "center",
          y: "center",
          textStyle: {
            fontSize: 16,
            color: "#0A0A0A"
          },
          subtextStyle: {
            fontSize: 16,
            color: "#0A0A0A",
            fontWeight: "bold"
          },
          itemGap: 2
        },
        backgroundColor: "#FFFFFF",
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["33%", "75%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: params => {
                  var colorList = ["#A7D4F6", "#90D97F", "#757575"];
                  return colorList[params.dataIndex];
                }
              }
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{a|{b}：{d}%}\n{hr|}",
              rich: {
                hr: {
                  backgroundColor: "t",
                  borderRadius: 2,
                  width: 3,
                  height: 3,
                  padding: [3, 3, 0, -12]
                },
                a: {
                  padding: [-30, 15, -20, 15]
                }
              }
            },
            labelLine: {
              normal: {
                length: 5,
                length2: 10,
                lineStyle: {
                  width: 1
                }
              }
            },
            data: [
              {
                name: this.sex.axisLabel[0],
                value: this.sex.series[0]
              },
              {
                name: this.sex.axisLabel[1],
                value: this.sex.series[1]
              },
              {
                name: this.sex.axisLabel[2],
                value: this.sex.series[2]
              }
            ]
          }
        ]
      });
    },
    // 评测预警数据
    charWarningData() {
      // 基于准备好的dom，初始化echarts实例
      let warningData = this.$echarts.init(
        document.getElementById("chart-warning-data")
      );
      // 绘制图表
      warningData.setOption({
        backgroundColor: "#FFFFFF",
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["33%", "75%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: params => {
                  var colorList = ["#A7D4F6", "#FEB77B"];
                  return colorList[params.dataIndex];
                }
              }
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{a|{b}：{d}%}\n{hr|}",
              rich: {
                hr: {
                  backgroundColor: "t",
                  borderRadius: 2,
                  width: 3,
                  height: 3,
                  padding: [3, 3, 0, -12]
                },
                a: {
                  padding: [-30, 15, -20, 15]
                }
              }
            },
            labelLine: {
              normal: {
                length: 10,
                length2: 10,
                lineStyle: {
                  width: 1
                }
              }
            },
            data: [
              {
                name: this.warning.axisLabel[0],
                value: this.warning.series[0]
              },
              {
                name: this.warning.axisLabel[1],
                value: this.warning.series[1]
              }
            ]
          }
        ]
      });
    },
    //每月数据图表
    chartMonthlyData() {
      let monthlyData = this.$echarts.init(
        document.getElementById("monthly-data")
      );
      monthlyData.setOption({
        backgroundColor: "#FFFFFF",
        title: {
          text: "每月数据测评",
          textStyle: {
            fontSize: 20
          },
          x: "center"
        },
        xAxis: {
          type: "category",
          data: this.mouth.axisLabel
        },
        yAxis: {
          type: "value"
          // max: 300
        },
        dataZoom: {
          height: 15,
          type: "slider", //图表下方的伸缩条
          show: true, //是否显示
          realtime: true,
          start: 1,
          end: 100
        },

        series: [
          {
            data: this.mouth.series,
            type: "bar",
            name: "柱状图",
            barWidth: 30,
            itemStyle: {
              normal: {
                //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                color: function(params) {
                  var colorList = [
                    "rgb(102,186,248)",
                    "rgb(35,150,231)",
                    "rgb(119,115,189)"
                  ];
                  return colorList[params.dataIndex % colorList.length];
                }
              }
            }
          },
          {
            data: this.mouth.series,
            type: "line",
            name: "折线图",
            symbolSize: 10, // 控制线条上 点 的大小
            itemStyle: {
              normal: {
                color: "#70CC58", //折点颜色
                label: {
                  show: true,
                  position: "top",
                  color: "#333",
                  fontSize: 14,
                  fontWeight: 700
                },
                borderWidth: 5,
                lineStyle: {
                  color: "#70CC58", //折线颜色
                  width: 5
                }
              }
            }
          }
        ]
      });
    },
    // 科室评测累计分布
    chartSectionDistributionData() {
      let sectionDistributionData = this.$echarts.init(
        document.getElementById("chart-section-distribution-data")
      );
      sectionDistributionData.setOption({
        backgroundColor: "#FFFFFF",
        title: {
          text: "各科室测评累计分布",
          textStyle: {
            fontSize: 20
          },
          x: "center"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          x: "center",
          y: "85%",
          data: this.department.legend
        },
        grid: {
          bottom: 100
        },
        yAxis: {
          show: false,
          type: "value"
        },
        xAxis: {
          type: "category",
          data: this.department.axisLabel,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          boundaryGap: false
        },
        dataZoom: {
          height: 15,
          type: "slider", //图表下方的伸缩条
          show: true, //是否显示
          realtime: true,
          start: 1,
          end: 100
        },
        series: [
          {
            name: this.department.legend[0],
            type: "bar",
            stack: "总量",
            barWidth: 17,
            itemStyle: {
              normal: {
                color: "#7773BD",
                barBorderRadius: [20, 20, 20, 20],
                shadowOffsetY: -10,
                shadowBlur: [0, 0, 0, 0],
                shadowColor: "#7773BD"
              }
            },
            label: {
              normal: {
                show: true,
                align: "center",
                color: "#030303",
                formatter: function(params) {
                  if (params.value > 0) {
                    return params.value;
                  } else {
                    return "";
                  }
                }
              }
            },

            z: 10,
            data: this.department.series[0]
          },
          {
            name: this.department.legend[1],
            type: "bar",
            stack: "总量",
            barWidth: 17,
            itemStyle: {
              normal: {
                barBorderRadius: [20, 20, 20, 20],
                shadowOffsetY: -10,
                shadowBlur: [0, 0, 0, 10],
                shadowColor: "#9EDDFF",
                color: "#9EDDFF"
              }
            },
            label: {
              normal: {
                show: true,
                align: "center",
                color: "#030303",

                formatter: function(params) {
                  if (params.value > 0) {
                    return params.value;
                  } else {
                    return "";
                  }
                }
              }
            },
            z: 5,
            data: this.department.series[1]
          },
          {
            name: this.department.legend[2],
            type: "bar",
            stack: "总量",
            barWidth: 17,
            itemStyle: {
              normal: {
                barBorderRadius: [20, 20, 20, 20],
                shadowOffsetY: -10,
                shadowBlur: [0, 0, 0, 10],
                shadowColor: "#73CB5B",
                color: "#73CB5B"
              }
            },
            label: {
              normal: {
                show: true,
                align: "center",
                color: "#030303",
                formatter: function(params) {
                  if (params.value > 0) {
                    return params.value;
                  } else {
                    return "";
                  }
                }
              }
            },
            data: this.department.series[2]
          }
        ]
      });
    },
    // 量表评测累计分布
    charScaleData() {
      let ageData = this.$echarts.init(
        document.getElementById("chart-scale-data")
      );
      ageData.setOption({
        backgroundColor: "#FFFFFF",
        title: {
          text: "量表评测累计分布",
          textStyle: {
            fontSize: 20
          },
          x: "center"
        },
        tooltip: {
          trigger: "axis",
          padding: [8, 10],
          backgroundColor: "rgba(0,0,0,0.5)",
          axisPointer: {
            type: "shadow",
            textStyle: {
              color: "#fff"
            }
          }
        },
        legend: {
          data: this.measure.legend,
          top: "10%"
        },
        grid: {
          // top: "20%",
          // containLabel: true,
          bottom: "10%",
          left: "25%"
        },
        label: {
          show: true,
          position: "right",
          color: "#333",
          fontSize: 14,
          fontWeight: 700
        },
        yAxis: [
          {
            type: "category",
            offset: 10,
            data: this.measure.yAxis,

            axisLabel: {
              show: true,
              textStyle: {
                color: "#333",
                fontSize: 12,
                fontWeight: 200
              },
              formatter: function(params) {
                //文字超出用...代替
                if (params.length > 8) {
                  params = params.slice(0, 6) + "\n" + params.slice(6);
                  return params.substring(0, 15) + "...";
                } else {
                  return params;
                }
              }
            }
          }
        ],
        xAxis: [
          {
            type: "value"
          }
        ],
        dataZoom: {
          width: 15,
          type: "slider", //图表下方的伸缩条
          show: true, //是否显示
          realtime: true,
          showDetail: false,
          start: 1,
          end: 4,
          orient: "vertical"
        },
        series: [
          {
            name: this.measure.legend[0],
            type: "bar",
            data: this.measure.series[0],
            barWidth: 10, //柱子宽度
            barGap: "0%", //柱子之间间距
            itemStyle: {
              normal: {
                color: "#88CFFD",
                opacity: 1,
                barBorderRadius: [0, 20, 20, 0]
              }
            }
          },
          {
            name: this.measure.legend[1],
            type: "bar",
            data: this.measure.series[1],
            barWidth: 10,
            barGap: "0%",
            itemStyle: {
              normal: {
                color: "#67CF7E",
                opacity: 1,
                barBorderRadius: [0, 20, 20, 0]
              }
            }
          }
        ]
      });
    },
    // 发送图表数据请求
    loadEchartsData() {
      loadEcharts().then(res => {
        ////console.log(res);
        if (res.code == 400200) {
          this.mouth = res.data.mouth_evaluating;
          this.department = res.data.department_evaluating;
          this.sex = res.data.sex_evaluating;
          this.warning = res.data.warning_evaluating;
          this.age = res.data.age_evaluating;
          this.measure = res.data.measure_evaluating;
          this.tatistical_user = res.data.statistical_user;
          this.data = res.data;
          this.loading = false;
        }

        ////console.log(this.sex);
      });
    }
  },
  watch: {
    data: function() {
      this.charAgeData();
      this.charSexData();
      this.chartMonthlyData();
      this.charScaleData();
      this.chartSectionDistributionData();
      this.charWarningData();
    }
  }
};
</script>

<style lang="less" scoped>
.index-tc {
  div {
    box-sizing: border-box;
  }
  height: 100%;
  min-height: 910px;
  padding-bottom: 10px;
  .header_title {
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(183, 245, 251);
    padding-bottom: 10px;
    p {
      font-size: 35px;
      font-weight: 700;
    }
  }
  .chart {
    height: calc(~"100% - 60px");
    display: flex;
    flex-direction: column;
    .chart-col-1 {
      width: 100%;
      height: 100%;
      flex: 4;
      display: flex;
      padding-bottom: 10px;
      .col-1-1 {
        flex: 2;
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        .col-1-1-1 {
          flex: 2;
          .num {
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            .numberback {
              width: 30%;
              height: 100%;
              background: url("../assets/images/sumback.png") no-repeat;
              background-size: 100%;
              background-position: center;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-content: center;
              p {
                text-align: center;
                font-size: 20px;
                font-weight: 700;
                background-image: -webkit-gradient(
                  linear,
                  left 0,
                  right 0,
                  from(rgb(197, 221, 231)),
                  to(rgb(4, 94, 170))
                );
                -webkit-background-clip: text; /*必需加前缀 -webkit- 才支持这个text值 */
                -webkit-text-fill-color: transparent; /*text-fill-color会覆盖color所定义的字体颜色： */
              }
            }
          }
        }
        .col-1-1-2 {
          flex: 4;
          margin-top: 10px;
          border: 1px solid #eeeeee;
        }
      }
      .col-1-2 {
        flex: 1;
        border: 1px solid #eeeeee;
      }
    }
    .chart-col-2 {
      width: 100%;
      height: 100%;
      flex: 3;
      display: flex;
      .col-2-1 {
        flex: 2;
        display: flex;
        margin-right: 10px;
        // border: 1px solid #eeeeee;
        .col-2-1-1 {
          flex: 5;
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          border: 1px solid #eeeeee;
          .col-2-1-1-1 {
            height: 100%;
            flex: 1;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            .title {
              flex: 1;
              height: 100%;
              font-size: 20px;
              font-weight: 700;
              letter-spacing: 2px;
              text-align: center;
              color: rgb(51, 51, 51);
            }
            .chart {
              flex: 11;
              height: 100%;
            }
          }
          .col-2-1-1-2 {
            height: 100%;
            flex: 1;
            display: flex;
            .title {
              flex: 1;
              height: 100%;
              font-size: 20px;
              font-weight: 700;
              letter-spacing: 2px;
              text-align: center;
              color: rgb(51, 51, 51);
            }
            .chart {
              flex: 11;
              height: 100%;
            }
          }
        }
        .col-2-1-2 {
          flex: 6;
          border: 1px solid #eeeeee;
        }
      }
      .col-2-2 {
        flex: 1;
        border: 1px solid #eeeeee;
      }
    }
  }
}
</style>